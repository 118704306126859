import { getCookie, getHash, setCookie } from "./functions";
import config from "../resources/config.json";
import { getDataCheckNewPaymentMethod, getDataNewPaymentMethod } from "./paymentMethods";
import { v4 as uuid } from 'uuid';
import { PAYMENT_STATUS } from "./constants";

const getHeaders = (lang=null) => {
    let headers = {
        'Accept': 'application/hal+json',
        'Cache-Control': 'no-cache'
    };
    if (lang !== null) {
        headers = {
            ...headers,
            'Accept-Language': lang
        }
    }
    return headers;
}

export const getCountries = async(data) => {
    let responseData;
    try {
        let url = 'https://api.' + data.environment + '/countries/';
        const response = await fetch(url, {
            headers: getHeaders(data.lang)
        });
        if (response.status == 200) {
            const mResponse = await response.json();
            responseData = {
                countries: [],
                provinces: []
            };
            Object.values(mResponse.countries).forEach(country => {
                responseData.countries[country.id] = country.name;
                if (country.provinces !== undefined) {
                    responseData.provinces[country.id] = [];
                    Object.values(country.provinces).forEach(province => {
                        responseData.provinces[country.id][province] = province;
                    });
                }
            });
        } else {
            responseData = false;
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const checkPaymentMethod = async(data) => {
    let responseData;
    try {
        let signature = '';
        signature += data.paymentMethod;
        signature += config.privateKey;
        signature = await getHash(signature);
        let url = 'https://api.' + data.environment + '/paymentMethods/' + data.paymentMethod + '/?signature=' + signature + '&check=true';
        const response = await fetch(url, {
            headers: getHeaders()
        });
        if (response.status == 200) {
            responseData = await response.json();
            delete responseData._links;
        } else {
            responseData = false;
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const getPaymentMethods = async(data) => {
    let responseData;
    try {
        let signature = '';
        signature += data.email;
        signature += config.privateKey;
        signature = await getHash(signature);
        let url = 'https://api.' + data.environment + '/paymentMethods/?email=' + data.email + '&signature=' + signature + '&check=true';
        const response = await fetch(url, {
            headers: getHeaders()
        });
        if (response.status == 200) {
            const mResponse = await response.json();
            responseData = [];
            Object.values(mResponse.paymentMethods).forEach(paymentMethod => {
                if (paymentMethod.status == PAYMENT_STATUS.SUCCESS || paymentMethod.status == PAYMENT_STATUS.PROCESSING) {
                    delete paymentMethod._links;
                    responseData.push(paymentMethod);
                }
            });
        } else {
            responseData = false;
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const getBilling = async(data) => {
    let responseData;
    try {
        let url = 'https://api.' + data.environment + '/billing/?token=' + data.tokenID;
        if (data.budget != '') {
            url += '&budget=' + data.budget
        }
        const response = await fetch(url, {
            headers: getHeaders()
        });
        if (response.status == 200) {
            responseData = await response.json();            
            delete responseData._links;
        } else {
            responseData = false;    
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const getBudget = async(data) => {
    let responseData;
    try {
        const url = 'https://api.' + data.environment + '/budgets/' + data.budget;
        const response = await fetch(url, {
            headers: getHeaders()
        });
        if (response.status == 200) {
            responseData = await response.json();
            delete responseData._links;
        } else {
            responseData = false;    
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const getInvoices = async(data) => {
    let responseData;
    try {
        let url = 'https://api.' + data.environment + '/invoices/?token=' + data.tokenID;
        if (data.budget != '') {
            url += '&budget=' + data.budget
        }
        const response = await fetch(url, {
            headers: getHeaders()
        });
        if (response.status == 200) {
            const mResponse = await response.json();            
            responseData = mResponse.invoices;
        } else {
            responseData = false;    
        }
    } catch (error) {
        responseData = false;
    }
    return responseData;
}

export const generateBudget = async(data) => {
    let responseData = false;
    try {
        const url = 'https://api.' + data.environment + '/budgets/';
        let signature = '';
        signature += data.user;
        signature += data.selectedLicense.id;
        signature += data.selectedLicense.periodicity;
        signature += data.selectedLicense.licenses;
        signature += data.selectedLicense.wantsAnInvoice;
        signature += data.selectedLicense.vat_number;
        signature += data.selectedLicense.name;
        signature += data.selectedLicense.address;
        signature += data.selectedLicense.town;
        signature += data.selectedLicense.province;
        signature += data.selectedLicense.zip_code;
        signature += data.selectedLicense.country;
        signature += data.selectedLicense.currency;
        signature += data.budget;
        signature += config.privateKey;
        signature = await getHash(signature);
        const parameters = {
            user: data.user,
            id: data.selectedLicense.id,
            periodicity: data.selectedLicense.periodicity,
            licenses: data.selectedLicense.licenses,
            invoice: data.selectedLicense.wantsAnInvoice,
            vat_number: data.selectedLicense.vat_number,
            name: data.selectedLicense.name,
            address: data.selectedLicense.address,
            town: data.selectedLicense.town,
            province: data.selectedLicense.province,
            zip_code: data.selectedLicense.zip_code,
            country: data.selectedLicense.country,
            currency: data.selectedLicense.currency,
            budget: data.budget,
            signature: signature
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: new URLSearchParams(parameters)
        });
        if (response.status == 200) {
            const responseJSON = await response.json();
            if (responseJSON.code != '') {
                responseData = {
                    code: responseJSON.code,
                    vat: responseJSON.vat
                };
            }
        }
    } catch (error) {
    }
    return responseData;
}

export const acceptBudget = async(data) => {
    let responseData = {
        status: 'error',
        errors: false,
        data: false
    };
    try {
        const url = 'https://api.' + data.environment + '/budgets/' + data.budget + ':accept/';
        let signature = '';
        signature += data.budget;
        signature += data.paymentMethod;
        signature += config.privateKey;
        signature = await getHash(signature);
        const parameters = {
            paymentMethod: data.paymentMethod,
            signature: signature
        };
        const response = await fetch(url, {
            method: 'PUT',
            headers: getHeaders(data.lang),
            body: new URLSearchParams(parameters)
        });
        const responseJSON = await response.json();
        if (response.status == 200) {
            responseData = {
                ...responseData,
                status: responseJSON.status,
                data: (responseJSON.data === undefined) ? false : responseJSON.data
            }
        } else {
            responseData = {
                ...responseData,
                status: 'error',
                errors: responseJSON.errors
            }
        }
    } catch (error) {
        responseData = {
            ...responseData,
            status: 'error',
            errors: ['unknown']
        }
    }
    return responseData;
}

const getPaymentMethodDataCache = {};

export const getPaymentMethodData = async(data) => {
    const url = 'https://api.' + data.environment + '/paymentMethods:data/';
    const dataPaymentMethod = await getDataCheckNewPaymentMethod( data.paymentMethod.type, data.paymentMethod.data );
    if (getPaymentMethodDataCache[dataPaymentMethod] === undefined) {
        let signature = '';
        signature += data.paymentMethod.type;
        signature += dataPaymentMethod;
        signature += config.privateKey;
        signature = await getHash(signature);
        const parameters = {
            type: data.paymentMethod.type,
            data: dataPaymentMethod,
            signature: signature
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: new URLSearchParams(parameters)
        });
        const responseData = await response.json();
        getPaymentMethodDataCache[dataPaymentMethod] = responseData;
        return responseData;
    } else {
        return getPaymentMethodDataCache[dataPaymentMethod];
    }
}

export const createPaymentMethod = async(data) => {
    let responseData = false;
    let payerIdentification = getCookie('payer');
    if (payerIdentification === undefined) {
        payerIdentification = uuid();
        setCookie('payer',payerIdentification,data.environmentCookies);
    }
    try {
        const url = 'https://api.' + data.environment + '/paymentMethods/';
        const dataPaymentMethod = await getDataNewPaymentMethod( data.paymentMethod.type, data.paymentMethod.data, data.environment );
        let signature = '';
        signature += data.email;
        signature += data.paymentMethod.type;
        signature += dataPaymentMethod;
        signature += data.budget;
        signature += data.return;
        signature += payerIdentification;
        signature += config.privateKey;
        signature = await getHash(signature);
        const parameters = {
            email: data.email,
            type: data.paymentMethod.type,
            data: dataPaymentMethod,
            budget: data.budget,
            return: data.return,
            nc: data.nc,
            payer: payerIdentification,
            signature: signature
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(data.lang),
            body: new URLSearchParams(parameters)
        });
        const responseJSON = await response.json();
        responseData = responseJSON;
    } catch (error) {
    }
    return responseData;
}

export const markBudgetAsSeen = async(data) => {
    try {
        const url = 'https://api.' + data.environment + '/budgets/' + data.budget + ':used/';
        await fetch(url, {
            method: 'POST',
            headers: getHeaders()           
        });
    } catch (error) {
    }
}

export const cancelPlan = async(data) => {
    let responseData = 'error';
    try {
        const url = 'https://api.' + data.environment + '/billing/?token=' + data.tokenID;
        let response = false;
        if (data.budget != '') {
            let signature = '';
            signature += data.email;
            signature += data.budget;
            signature += config.privateKey;
            signature = await getHash(signature);
            const parameters = {
                email: data.email,
                budget: data.budget,           
                signature: signature
            };
            response = await fetch(url, {
                method: 'DELETE',
                headers: getHeaders(data.lang),
                body: new URLSearchParams(parameters)
            });
        } else {
            response = await fetch(url, {
                method: 'DELETE',
                headers: getHeaders(data.lang)
            });
        }
        if (response.status == 200) {
            const responseJSON = await response.json();
            responseData = responseJSON.status;
        }
    } catch (error) {
    }
    return responseData;
}