import { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../contexts/main';
import { isPayable } from '../../helpers/billing';
import { getText } from '../../helpers/language';
import { SVG_shoppingBag, SVG_corg } from '../elements/SVG';
import { BlockInfoLicense } from './BlockInfoLicense';
import { BlockLoading } from './BlockLoading';
import config from '../../resources/config.json';
import { BlockPrices } from './BlockPrices';

export const BlockPayAction = ({ handleBuy }) => {

    const { mainReducer, appReducer } = useContext( MainContext );

    const [ stateVisible, setStateVisible ] = useState(false);   
    const [ stateNeedAuthorization, setStateNeedAuthorization ] = useState(false);   

    useEffect( () => {
        if ((mainReducer.state.newPaymentMethod === false) && (mainReducer.state.currentPaymentMethod.id == mainReducer.state.selectedPaymentMethod) && (mainReducer.state.user.email != mainReducer.state.currentPaymentMethod.payer)) {
            setStateNeedAuthorization(true);
        } else {
            setStateNeedAuthorization(false);
        }
        
    }, [ mainReducer.state.selectedPaymentMethod, mainReducer.state.newPaymentMethod, mainReducer.state.currentPaymentMethod ]);

    useEffect( () => {
        if (isPayable( mainReducer )) {
            setStateVisible(true);
        } else {
            setStateVisible(false);
        }
    }, [ mainReducer.state.selectedLicense, mainReducer.state.currentLicense, mainReducer.state.selectedPaymentMethod, mainReducer.state.currentPaymentMethod, mainReducer.state.newPaymentMethod ]);

    const handleOpenLicense = () => {
        appReducer.dispatch({
            type: 'openModal',
            payload: {
                id: 'license'
            }
        });
    }

    const handleOnChangeLegal = ({ target }) => {
        mainReducer.dispatch({
            type: 'setAcceptedConditions',
            payload: target.checked
        });
    }

    if (stateVisible) {
        return  <>
                <div className={`e__plns__cta e__plns__cta--${mainReducer.state.selectedLicense.id}${(config.plans[mainReducer.state.selectedLicense.id].highlight) ? ' e__plns__cta--highlight' : ''}`}>
                    {((mainReducer.state.selectedLicense.id != mainReducer.state.currentLicense.id) || (mainReducer.state.selectedLicense.periodicity != mainReducer.state.currentLicense.periodicity) || (mainReducer.state.selectedLicense.licenses != mainReducer.state.currentLicense.licenses)) &&
                        <>
                        <BlockInfoLicense license={ mainReducer.state.selectedLicense } />
                        <BlockPrices licenseId={ mainReducer.state.selectedLicense.id } />
                        <div className="e__form">
                            <input type="checkbox" id="legalCheck" checked={ mainReducer.state.acceptedConditions } onChange={ handleOnChangeLegal } />
                            <label htmlFor="legalCheck">{ getText('licencias_y_condiciones_disclaimer_2') } </label><button onClick={ handleOpenLicense } className="e__lnk" >{ getText('licencias_y_condiciones') }</button>
                        </div>
                        </>
                    }
                    {((mainReducer.state.paymentMethods === false) || (mainReducer.state.countries === false) || (mainReducer.state.budgetGenerated === false)) ?
                        <BlockLoading />
                    :
                        <div className="e__plns__cta__btn">
                            <button className="e-btn e-btn__primary e-btn--max" onClick={ handleBuy }>
                                <span className="e__btn__ico">
                                    {(mainReducer.state.currentLicense.id == 0) ?
                                        <SVG_shoppingBag />
                                    :
                                        <SVG_corg />
                                    }
                                </span>
                                <span className="e__btn__txt">
                                    {   getText(
                                        (mainReducer.state.inProgressLicense.id == 0) ?
                                            'confirmar_suscripcion'
                                        :
                                            (stateNeedAuthorization) ?
                                                'solicitar_cambio'
                                            :
                                                (mainReducer.state.ratesApplied[mainReducer.state.selectedLicense.id].totalNow == 0) ?
                                                    'cambiar_suscripcion'
                                                :
                                                    'cambiar_suscripcion_y_pagar'
                                        )
                                    }
                                </span>
                            </button>
                        </div>
                    }
                </div>
                </>;
    } else {
        return  <></>;
    }
}