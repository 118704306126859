import { useEffect, useRef, useState } from "react";
import { SVG_cardBrand_amex, SVG_cardBrand_cartes_bancaires, SVG_cardBrand_diners, SVG_cardBrand_discover, SVG_cardBrand_jcb, SVG_cardBrand_mastercard, SVG_cardBrand_unionpay, SVG_cardBrand_visa } from "../elements/SVGCardBrands";
import styles from './BlockCardBrands.module.scss';
import { getText } from "../../helpers/language";

export const BlockCardBrands = ({ availableCardNetworks, field, value, handleSave, isFailed }) => {

    // Cartes bancaires demo card: 4972 9109 6249 8461
    
    const rotateExtraBrandsTime = 4000;

    const mounted = useRef(false);
    const refExtraBrandsDisplayed = useRef(1);

    const [ stateExtraBrandsDisplayed, setStateExtraBrandsDisplayed ] = useState( 1 );

    useEffect(() => {
        mounted.current = true;
        setTimeout(rotateExtraBrands,rotateExtraBrandsTime);
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect( () => {
        
    }, [ availableCardNetworks, value ]);

    useEffect( () => {
        switch (availableCardNetworks.length) {
        case 0:
        case 1:
            if (value != '') {
                handleSave( '', field );
            }
            break;
        default:
            if ( !availableCardNetworks.includes(value) ) {
                handleSave( 'cartes_bancaires', field );
            }
        }
    }, [ availableCardNetworks ]);

    const rotateExtraBrands = () => {
        if (mounted.current) {
            if (refExtraBrandsDisplayed.current < 5) {
                refExtraBrandsDisplayed.current++;
            } else {
                refExtraBrandsDisplayed.current = 1;
            }
            setStateExtraBrandsDisplayed(refExtraBrandsDisplayed.current);
            setTimeout(rotateExtraBrands,rotateExtraBrandsTime);
        }
    }

    const selectNetwork = ({ target }) => {
        handleSave( target.value, field );
    }

    return  <>
                <div className={ styles['cards'] }
                    data-failed={isFailed ?  'true' : 'false'}
                >
                    { (availableCardNetworks.length == 0) ? <>
                        <div><SVG_cardBrand_visa /></div>
                        <div><SVG_cardBrand_mastercard /></div>
                        <div><SVG_cardBrand_amex /></div>
                        <div>
                        {(stateExtraBrandsDisplayed == 1 ) && <SVG_cardBrand_discover /> }
                        {(stateExtraBrandsDisplayed == 2 ) && <SVG_cardBrand_diners /> }
                        {(stateExtraBrandsDisplayed == 3 ) && <SVG_cardBrand_jcb /> }
                        {(stateExtraBrandsDisplayed == 4 ) && <SVG_cardBrand_unionpay /> }
                        {(stateExtraBrandsDisplayed == 5 ) && <SVG_cardBrand_cartes_bancaires /> }
                        </div>
                    </>: (availableCardNetworks.length == 1) ? <>
                        <div>
                        { (availableCardNetworks[0] == 'visa') && <SVG_cardBrand_visa /> }
                        { (availableCardNetworks[0] == 'mastercard') && <SVG_cardBrand_mastercard /> }
                        { (availableCardNetworks[0] == 'amex') && <SVG_cardBrand_amex /> }
                        { (availableCardNetworks[0] == 'discover') && <SVG_cardBrand_discover /> }
                        { (availableCardNetworks[0] == 'diners') && <SVG_cardBrand_diners /> }
                        { (availableCardNetworks[0] == 'jcb') && <SVG_cardBrand_jcb /> }
                        { (availableCardNetworks[0] == 'unionpay') && <SVG_cardBrand_unionpay /> }
                        </div>
                    </>:<>
                        <div>
                        { (value == 'visa') && <SVG_cardBrand_visa /> }
                        { (value == 'mastercard') && <SVG_cardBrand_mastercard /> }
                        { (value == 'cartes_bancaires') && <SVG_cardBrand_cartes_bancaires /> }
                        </div>
                        <select onChange={ selectNetwork } value={ value }>
                        { availableCardNetworks.map( network =>
                            <option key={ network } value={ network }>{ getText('network_' + network) }</option>
                        )}    
                        </select>
                    </>
                    }
                </div>
            </>;
}