import { BlockSummaryPaymentExtraData_sepa_debit } from './BlockSummaryPaymentExtraData_sepa_debit';
import { BlockSummaryPaymentExtraData_stripe_card } from './BlockSummaryPaymentExtraData_stripe_card';

export const BlockSummaryPaymentExtraData = ({ type }) => {

    switch (type) {
    case 'stripe_card':
        return  <BlockSummaryPaymentExtraData_stripe_card />
        break;
    case 'sepa_debit':
        return <BlockSummaryPaymentExtraData_sepa_debit/>;
        break;
    default:
        return <></>;
    }
        
}