import { useEffect, useContext, useState, useRef } from 'react';
import { MainContext } from '../../contexts/main';
import { acceptBudget, cancelPlan, checkPaymentMethod, createPaymentMethod, getBilling, getBudget } from '../../helpers/api';
import { markErrorNewPaymentMethodFields, validateNewPaymentMethod } from '../../helpers/paymentMethods';
import { ScreenDataMain } from './ScreenDataMain';
import { ScreenLoading } from './ScreenLoading';
import { ScreenDataPending } from './ScreenDataPending';
import { isPayable } from '../../helpers/billing';
import { PostRedirecter } from '../elements/PostRedirecter';
import { getURL } from '../../helpers/urls';
import { getText } from '../../helpers/language';
import { checkNIF } from '../../helpers/functions';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const ScreenData = ({ mode, currentLocation }) => {

    const context = useContext( MainContext );

    const { environment, mainReducer, appReducer } = context;

    const [ stateScreen, setStateScreen ] = useState('loading');
    const [ stateCheckAuthPaymentMethod, setStateCheckAuthPaymentMethod ] = useState(environment.checkAuthPaymentMethod == '' ? false : environment.checkAuthPaymentMethod);
    const [ statePostRedirecter, setStatePostRedirecter ] = useState(false);

    const checkingPayment = useRef(false);
    const noGoOut = useRef(false);
    const mounted = useRef(false);

    const onUnload = (e) => {
        if (noGoOut.current) {
            e.returnValue = 'confirmationMessage';
        }
    }

    useEffect(() => {
        mounted.current = true;
        window.addEventListener('beforeunload', onUnload);
        return () => {
            mounted.current = false;
            window.removeEventListener('beforeunload', onUnload);
        }
    }, []);

    useEffect(() => {
        if ((mainReducer.state.selectedLicense.payer_email != '') && (mainReducer.state.paymentMethods !== false)) {
            if (stateCheckAuthPaymentMethod == '') {
                setStateScreen('main');
            } else {
                if (checkingPayment.current === false) {
                    checkingPayment.current = 1;
                    setStateScreen('checking_payment');
                    setTimeout(checkAuthPaymentMethod,2000);
                }
            }
            
        }
    }, [ mainReducer.state.selectedLicense.payer_email, stateCheckAuthPaymentMethod, mainReducer.state.paymentMethods ]);

    useEffect( () => {
        if (stateScreen == 'main') {
            if ((isPayable( mainReducer )) && (stateScreen == 'main')) {
                noGoOut.current = true;
            } else {
                noGoOut.current = false;
            }
        } else {
            noGoOut.current = true;
        }
    }, [ stateScreen, mainReducer.state.selectedLicense, mainReducer.state.currentLicense, mainReducer.state.selectedPaymentMethod, mainReducer.state.currentPaymentMethod, mainReducer.state.newPaymentMethod ]);

    useEffect(() => {
        if ((!mainReducer.state.loadingBillingData) && (checkingPayment.current == 2)) {
            checkingPayment.current = 3;
        } if (checkingPayment.current == 3) {
            mainReducer.state.paymentMethods.forEach( ( paymentMethod ) => {
                if (paymentMethod.id == stateCheckAuthPaymentMethod) {
                    checkingPayment.current = 4;
                    let notificationText;
                    const message = mainReducer.state.currentPaymentMethod.status === PAYMENT_STATUS.PROCESSING ? 'notification_payment_partially_complete' :'notification_payment_complete';
                    if (mainReducer.state.currentLicense.licenses > 1) {
                        if ((mainReducer.state.realUser !== false) && (mainReducer.state.realUser.id == mainReducer.state.user.id)) {
                            notificationText = <>
                                { getText(message) }. { getText('notification_payment_complete_licenses') } <a href={`${ getURL( environment, 'perfilUsuario' ) }${ mainReducer.state.realUser.slug }licenses/`} title={ getText('gestionar_licencias') }>{ getText('gestionar_licencias') }</a>
                            </>;
                        } else {
                            notificationText = getText(message) + '. ' + getText('notification_payment_complete_licenses_noadmin');
                        }
                    } else {
                        notificationText = getText(message);
                    }
                    appReducer.dispatch({
                        type: 'setNotifications',
                        payload: [{
                            type: 'ok',
                            content: notificationText
                        }]
                    });
                    setStateCheckAuthPaymentMethod(false);
                }
            });
        }
    }, [ mainReducer.state.loadingBillingData, mainReducer.state.paymentMethods, checkingPayment.current ]);

    const handleBuy = () => {
        const validations = validBuy();
        if ((validations.notifications.length == 0) && (validations.errors.length == 0)) {
            appReducer.dispatch({
                type: 'clearFieldErrors',
            });
            appReducer.dispatch({
                type: 'clearNotifications',
            });
            setStateScreen('loading');
            if (mainReducer.state.newPaymentMethod === false) {
                buy();
            } else {
                createPaymentMethod({
                    environment: environment.entorno,
                    environmentCookies: environment.entornoCookies,
                    lang: environment.lang,
                    email: mainReducer.state.selectedLicense.payer_email,
                    budget: mainReducer.state.budgetGenerated,
                    return: currentLocation,
                    nc: environment.nc,
                    paymentMethod: mainReducer.state.newPaymentMethod
                }).then(response => {
                    switch (response.status) {
                    case 'error':
                        if (response.errors.includes('payment_method_creation_blocked')) {
                            appReducer.dispatch({
                                type: 'setNotifications',
                                payload: [
                                    {
                                        type: 'error',
                                        content: getText('notification_payment_method_creation_blocked')
                                    }
                                ]
                            });
                        } else {
                            appReducer.dispatch({
                                type: 'setNotifications',
                                payload: [
                                    {
                                        type: 'error',
                                        content: getText('notification_bad_payment')
                                    }
                                ]
                            });
                            const errorsCreatePaymentMethod = markErrorNewPaymentMethodFields( mainReducer.state.newPaymentMethod.type );
                            if (errorsCreatePaymentMethod.length > 0) {
                                appReducer.dispatch({
                                    type: 'setFieldErrors',
                                    payload: errorsCreatePaymentMethod
                                }); 
                            }
                        }
                        setStateScreen('main');
                        break;
                    case PAYMENT_STATUS.SUCCESS:
                        setStateCheckAuthPaymentMethod(response.id);
                        break;
                    case PAYMENT_STATUS.PENDING:
                        setStateScreen('loading_payment_gateway');
                        setTimeout(() => redirectTo(response.action),3000);
                        break;
                    }
                });
            }
        } else {
            appReducer.dispatch({
                type: 'setFieldErrors',
                payload: validations.errors
            });
            appReducer.dispatch({
                type: 'setNotifications',
                payload: validations.notifications
            });
        }
    }

    const redirectTo = ( url ) => {
        noGoOut.current = false;
        location.href = url;
    }

    const validBuy = () => {
        const errors = [];
        const notifications = [];
        if (mainReducer.state.newPaymentMethod === false) {
            if (mainReducer.state.selectedPaymentMethod === false) {
                notifications.push({
                    type: 'error',
                    content: getText('notification_select_payment_method')
                });
            } else {
                if ((mainReducer.state.currentPaymentMethod.status != PAYMENT_STATUS.SUCCESS) || (mainReducer.state.currentPaymentMethod.id != mainReducer.state.selectedPaymentMethod)) {
                    let validPaymentMethod = false;
                    mainReducer.state.paymentMethods.forEach( ( paymentMethod ) => {
                        if (paymentMethod.id == mainReducer.state.selectedPaymentMethod) {
                            validPaymentMethod = true;
                        }
                    });
                    if (!validPaymentMethod) {
                        notifications.push({
                            type: 'error',
                            content: getText('notification_bad_payment')
                        });
                    }
                }
            }
        } else {
            if (mainReducer.state.newPaymentMethod.type === false) {
                notifications.push({
                    type: 'error',
                    content: getText('notification_select_payment_method')
                });
            } else {
                const newPaymentMethodErrors = validateNewPaymentMethod( mainReducer.state.newPaymentMethod.type, mainReducer.state.newPaymentMethod.data );
                newPaymentMethodErrors.forEach(( error ) => {
                    errors.push( error );
                });
                if (newPaymentMethodErrors.length > 0) {
                    notifications.push({
                        type: 'error',
                        content: getText('notification_bad_payment')
                    });
                }
            }
        }
        if (mainReducer.state.selectedLicense.wantsAnInvoice) {
            let hasError = false;           
            const fields = ['name','address','town','province','zip_code'];
            fields.forEach(( field ) => {
                if (mainReducer.state.selectedLicense[ field ].trim() == '') {
                    errors.push({
                        section: 'billing',
                        field: field,
                        error: 'empty'
                    });
                    hasError = true;
                }
            });

            if (mainReducer.state.selectedLicense[ 'vat_number' ].trim() == '') {
                errors.push({
                    section: 'billing',
                    field: 'vat_number',
                    error: 'empty'
                });
                hasError = true;
            } else {
                if ((mainReducer.state.selectedLicense.country == 'ES') && (checkNIF(mainReducer.state.selectedLicense[ 'vat_number' ]) === false)) {
                    errors.push({
                        section: 'billing',
                        field: 'vat_number',
                        error: 'wrong_vat_number'
                    });
                    hasError = true;
                }
            }

            if (hasError) {
                notifications.push({
                    type: 'error',
                    content: getText('notification_empty_billing')
                });
            }
        }
        if (!mainReducer.state.acceptedConditions) {
            if ((mainReducer.state.selectedLicense.id != mainReducer.state.currentLicense.id) || (mainReducer.state.selectedLicense.periodicity != mainReducer.state.currentLicense.periodicity) || (mainReducer.state.selectedLicense.licenses != mainReducer.state.currentLicense.licenses)) {
                notifications.push({
                    type: 'error',
                    content: getText('notification_conditions_not_accepted')
                });
            }
        }
        return {
            notifications: notifications,
            errors: errors
        };
    }

    const checkBudget = () => {
        getBudget({
            environment: environment.entorno,
            budget: environment.budget
        }).then(budget => {
            if (mounted.current) {
                if (budget.running) {
                    setTimeout(checkBudget,1000);
                } else {
                    if (budget.accomplished) {
                        goToFinalScreen('payment_complete');
                    } else {
                        appReducer.dispatch({
                            type: 'setNotifications',
                            payload: [{
                                type: 'error',
                                content: getText('notification_payment_payment_could_not_be_made')
                            }]
                        });
                        setStateScreen('main');
                    }
                }
            }
        });
    }

    const checkAuthPaymentMethod = () => {
        if (mounted.current) {
            checkPaymentMethod({
                environment: environment.entorno,
                paymentMethod: stateCheckAuthPaymentMethod
            }).then(paymentMethod => {
                if ((paymentMethod.status === false) || (paymentMethod.status == PAYMENT_STATUS.PENDING)) {
                    setTimeout(checkAuthPaymentMethod,2000);
                } else {
                    if (paymentMethod.status == PAYMENT_STATUS.SUCCESS || paymentMethod.status == PAYMENT_STATUS.PROCESSING) {
                        mainReducer.dispatch({
                            type: 'invalidateBillingData'
                        });
                        let addPaymentMethod = true;
                        mainReducer.state.paymentMethods.forEach( ( paymentMethodCheck ) => {
                            if (paymentMethodCheck.id == paymentMethod.id) {
                                addPaymentMethod = false;
                            }
                        });
                        if (addPaymentMethod) {
                            mainReducer.dispatch({
                                type: 'addPaymentMethod',
                                payload: paymentMethod
                            });
                        }
                        getBilling({
                            tokenID: environment.tokenID,
                            environment: environment.entorno,
                            budget: environment.budget
                        }).then(billingData => {
                            checkingPayment.current = 2;
                            if (billingData === false) {
                                appReducer.dispatch({
                                    type: 'setError',
                                    payload: 'carga'
                                });
                            } else {
                                mainReducer.dispatch({
                                    type: 'setBillingData',
                                    payload: billingData,
                                    environment: environment
                                });
                            }
                        });
                    } else {
                        setStateCheckAuthPaymentMethod(false);
                        appReducer.dispatch({
                            type: 'setNotifications',
                            payload: [
                                {
                                    type: 'error',
                                    content: getText('notification_payment_payment_could_not_be_made')
                                }
                            ]
                        });
                    }
                }
            });
        }
    }

    const buy = async ( paymentMethod = 0 ) => {
        if (paymentMethod == 0) {
            paymentMethod = mainReducer.state.selectedPaymentMethod;
        }
        const response = await acceptBudget({
            environment: environment.entorno,
            lang: environment.lang,
            budget: mainReducer.state.budgetGenerated,
            paymentMethod: paymentMethod
        });
        switch (response.status) {
        case PAYMENT_STATUS.SUCCESS:
            goToFinalScreen('payment_complete');
            break;
        case PAYMENT_STATUS.PENDING:
            setStateScreen('pendingBuy');
            break;
        default:
            if (response.errors.includes('budget_running')) {
                setTimeout(checkBudget,1000);
            } else {
                const payloadErrors = [];
                for (const error of response.errors) {
                    payloadErrors.push({
                        type: 'error',
                        content: getText('notification_payment_' + error)
                    });
                }
                appReducer.dispatch({
                    type: 'setNotifications',
                    payload: payloadErrors
                });
                setStateScreen('main');
            }
            break;
        }
    }

    const goToFinalScreen = ( action ) => {
        let url;
        if (mainReducer.state.user.id == mainReducer.state.realUser.id) {
            url = getURL( environment, 'perfilUsuario' ) + mainReducer.state.realUser.slug + 'plan/';
        } else {
            url = getURL( environment, 'premiumCheckout' ) + '?budget=' + mainReducer.state.budgetGenerated + '&email=' + mainReducer.state.selectedLicense.payer_email;
            if ( environment.nc ) {
                url += '&nc=true';
            }
        }
        noGoOut.current = false;
        setStatePostRedirecter({
            url: url,
            parameters: [
                {
                    name: 'action',
                    value: action
                }
            ]
        });
    }

    const handleCancel = () => {
        if (window.confirm(getText('confirm_cancel'))) {
            appReducer.dispatch({
                type: 'clearFieldErrors',
            });
            appReducer.dispatch({
                type: 'clearNotifications',
            });
            setStateScreen('loading');
            cancelPlan({
                environment: environment.entorno,
                lang: environment.lang,
                tokenID: environment.tokenID,
                environment: environment.entorno,
                budget: environment.budget,
                email: mainReducer.state.selectedLicense.payer_email
            }).then(status => {
                switch (status) {
                case PAYMENT_STATUS.SUCCESS:
                    goToFinalScreen('cancel_complete');
                    break;
                case PAYMENT_STATUS.PENDING:
                    setStateScreen('pendingCancel');
                    break;
                default:
                    appReducer.dispatch({
                        type: 'setNotifications',
                        payload: [
                            {
                                type: 'error',
                                content: getText('notification_cancel_error')
                            }
                        ]
                    });
                    setStateScreen('main');
                    break;
                }
            });
        }
    }

    switch (stateScreen) {
    case 'main':
        return  <ScreenDataMain mode={ mode } handleBuy={ handleBuy } handleCancel={ handleCancel } />;
        break;
    case 'pendingBuy':
        return  <ScreenDataPending type="buy" />
        break;
    case 'pendingCancel':
        return  <ScreenDataPending type="cancel" />
        break;
    case 'checking_payment':
        return  <ScreenLoading text="comprobando_autorizacion" />;
        break;
    case 'loading_payment_gateway':
        return  <ScreenLoading text="redirigiendo_pasarela" />;
        break;
    default:
        return  <>
                    <PostRedirecter data={ statePostRedirecter } />
                    <ScreenLoading />
                </>;
    }
    
}