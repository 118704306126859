import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { navigateTo } from '../../helpers/urls';
import OutsideAlerter from '../elements/OutsideAlerter';
import config from '../../resources/config.json';
import { generateBudget } from '../../helpers/api';
import { BlockPayForMe } from '../blocks/BlockPayForMe';
import { BlockDownloadBudget } from '../blocks/BlockDownloadBudget';
import { BlockInfoLicense } from '../blocks/BlockInfoLicense';
import { SVG_close, SVG_invoice_green } from '../elements/SVG';

export const ModalBudget = () => {

    const { environment, appReducer, mainReducer } = useContext( MainContext );

    const [ stateBudget, setStateBudget ] = useState(false);

    const navigate = useNavigate();

    useEffect( () => {
        if (mainReducer.state.user === false) {
            let code = mainReducer.state.selectedLicense.id;
            code += config.periodicities[mainReducer.state.selectedLicense.periodicity].code;
            code += mainReducer.state.selectedLicense.licenses
            setStateBudget(code);
        } else {
            if (mainReducer.state.budgetGenerated === false) {
                const data = {
                    selectedLicense: mainReducer.state.selectedLicense,
                    environment: environment.entorno,
                    user: mainReducer.state.user.id,
                    budget: environment.budget
                };
                generateBudget(data).then(budgetData => {
                    mainReducer.dispatch({
                        type: 'setBudgetGenerated',
                        payload: budgetData
                    });
                    
                });
            }
        }
    }, []);

    const handleClose = () => {
        const action = {
            type: 'closeModal',
            payload: 'budget'
        }
        appReducer.dispatch(action);
    }

    const handleAddBillingDetails = () => {
        handleClose();
        navigateTo('premiumBudget', navigate, environment, appReducer);
    }

    return  <>
            <div className="e__modal">
                <OutsideAlerter action={ handleClose }>
                <div className="e__modal__bdgt">
                    <button className="e__btn e__btn__close" onClick={ handleClose }><SVG_close /></button>
                    <div className="e__modal__bdgt__hd">
                        <span className="e__ico"><SVG_invoice_green /></span>
                        { getText('presupuesto') }
                    </div>
                    <div className="e__modal__bdgt__wpr">
                        <BlockInfoLicense license={ mainReducer.state.selectedLicense } />
                        <span className="e__txt__p">
                            <button className="e__lnk" onClick={handleAddBillingDetails}>{ getText('anadir_datos_de_facturacion') }</button>
                        </span>
                        <BlockDownloadBudget overrideCode={stateBudget} inContext={true} />
                    </div>
                    <div className="e__modal__bdgt__ftr">
                        {(mainReducer.state.user !== false) && <BlockPayForMe /> }
                    </div>
                </div>
                </OutsideAlerter>
                <div className="e__modal__bckg"></div>
            </div>
            </>;

}