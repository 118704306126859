import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { SVG_alert, SVG_okcheck, SVG_paymentMethod, SVG_xcircle } from '../elements/SVG';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const BlockSummaryPaymentSaved = ({ changeScreen }) => {

    const { mainReducer, environment } = useContext(MainContext);

    const [stateShowCurrentPaymentMethod, setStateShowCurrentPaymentMethod] = useState(false);
    const currentPaymentMethod = mainReducer.state.currentPaymentMethod;
    const currentPaymentMethodStatus = mainReducer.state.currentPaymentMethod.status;
    const paymentMethods = mainReducer.state.paymentMethods;
    const realUser = mainReducer.state.realUser;
    const selectedPaymentMethod = mainReducer.state.selectedPaymentMethod;

    useEffect(() => {

        if ((realUser === false) || (currentPaymentMethod === false) || ((realUser !== false) && (realUser.email != currentPaymentMethod.payer))) {
            setStateShowCurrentPaymentMethod(false);
            return;
        }
        const currentPaymentMethodInList = paymentMethods.some(paymentMethod => paymentMethod.id == currentPaymentMethod.id);
        setStateShowCurrentPaymentMethod(!currentPaymentMethodInList);

    }, [paymentMethods, currentPaymentMethod, currentPaymentMethodStatus]);

    const handleOnChange = (paymentMethod) => {
        const action = {
            type: 'changePaymentMethod',
            payload: paymentMethod
        };
        mainReducer.dispatch(action);
    }

    return <>
        <div className="e__plns__opt__pay__wpr">
            {(((mainReducer.state.realUser !== false) && (currentPaymentMethod !== false) && (mainReducer.state.realUser.email != currentPaymentMethod.payer))) &&
                <div className="e__plns__opt__pay__wpr__save">
                    <input type="radio" id={`paymentMethod${currentPaymentMethod.id}`} value={currentPaymentMethod.id} onChange={() => handleOnChange(currentPaymentMethod.id)} checked={(mainReducer.state.selectedPaymentMethod !== false) && (mainReducer.state.selectedPaymentMethod == currentPaymentMethod.id)} />
                    <label htmlFor={`paymentMethod${currentPaymentMethod.id}`}>
                        <span className="e__plns__opt__pay__wpr__save__ico">
                            <SVG_paymentMethod type="payer" />
                        </span>
                        <div className="e__plns__opt__pay__wpr__save__info">
                            <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                <div className="e__plns__opt__pay__wpr__save__info__hd">{getText('payment_method_title_payer')}</div>
                                <div className="e__plns__opt__pay__wpr__save__info__db">{currentPaymentMethod.payer}</div>
                            </div>
                        </div>
                        <div className='e__plns__opt__pay__info__wrapper'>
                            {(currentPaymentMethod.status === PAYMENT_STATUS.PROCESSING) &&
                                <div>
                                    <SVG_alert />
                                    {getText('payment_method_pending')}
                                </div>
                            }
                            <span className="e__ico">
                                {(currentPaymentMethod.id == selectedPaymentMethod) &&
                                    <>
                                        {(currentPaymentMethodStatus == PAYMENT_STATUS.SUCCESS) ?
                                            <SVG_okcheck />
                                            :
                                            <SVG_xcircle />
                                        }
                                    </>
                                }
                            </span>
                        </div>
                    </label>
                </div>
            }
            {mainReducer.state.paymentMethods.map(paymentMethod =>
                <div className="e__plns__opt__pay__wpr__save" key={paymentMethod.id}>
                    <input type="radio" id={`paymentMethod${paymentMethod.id}`} value={paymentMethod.id} onChange={() => handleOnChange(paymentMethod.id)} checked={(mainReducer.state.selectedPaymentMethod !== false) && (mainReducer.state.selectedPaymentMethod == paymentMethod.id)} />
                    <label htmlFor={`paymentMethod${paymentMethod.id}`}>
                        <span className="e__plns__opt__pay__wpr__save__ico">
                            <SVG_paymentMethod type={paymentMethod.type} />
                        </span>
                        <div className="e__plns__opt__pay__wpr__save__info">
                            <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                <div className="e__plns__opt__pay__wpr__save__info__hd">{getText('payment_method_title_' + paymentMethod.type)}</div>
                                <div className="e__plns__opt__pay__wpr__save__info__db">{getText('payment_method_prefix_' + paymentMethod.type)} {paymentMethod.description}</div>
                            </div>
                            {(paymentMethod.expires !== false) &&
                                <div className="e__plns__opt__pay__wpr__save__info__date">{getText('caduca')}: {tsToDate(paymentMethod.expires, environment.lang, 'paymentMethod')}</div>
                            }
                        </div>

                        <div className='e__plns__opt__pay__info__wrapper'>
                            {(paymentMethod.status === PAYMENT_STATUS.PROCESSING) &&
                                <div>
                                    <SVG_alert />
                                    {getText('payment_method_pending')}
                                </div>
                            }
                            {(paymentMethod.id == selectedPaymentMethod) &&
                                <span className="e__ico">
                                    <SVG_okcheck />
                                </span>
                            }
                        </div>

                    </label>
                </div>
            )}
            {(stateShowCurrentPaymentMethod) &&
                <div className="e__plns__opt__pay__wpr__save e__plns__opt__pay__wpr__save--rrr">
                    <div className="e__plns__opt__pay__wpr__save__item">
                        <span className="e__plns__opt__pay__wpr__save__ico">
                            <SVG_paymentMethod type={currentPaymentMethod.type} />
                        </span>
                        <div className="e__plns__opt__pay__wpr__save__info">
                            <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                <div className="e__plns__opt__pay__wpr__save__info__hd">{getText('payment_method_title_' + currentPaymentMethod.type)}</div>
                                <div className="e__plns__opt__pay__wpr__save__info__db">{getText('payment_method_prefix_' + currentPaymentMethod.type)} {currentPaymentMethod.description}</div>
                            </div>
                            {(currentPaymentMethod.expires !== false) &&
                                <div className="e__plns__opt__pay__wpr__save__info__date">{getText('caduca')}: {tsToDate(currentPaymentMethod.expires, environment.lang, 'paymentMethod')}</div>
                            }
                        </div>
                        <div className='e__plns__opt__pay__info__wrapper'>
                            {(currentPaymentMethod.status === PAYMENT_STATUS.PROCESSING) &&
                                <div>
                                    <SVG_alert />
                                    {getText('payment_method_pending')}
                                </div>
                            }
                            <span className="e__ico">
                                {(currentPaymentMethod.id == selectedPaymentMethod) &&
                                    <>
                                        {(currentPaymentMethodStatus == PAYMENT_STATUS.SUCCESS) ?
                                            <SVG_okcheck />
                                            :
                                            <SVG_xcircle />
                                        }
                                    </>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            }
            <button className="e__btn e__btn__trtry" onClick={() => changeScreen('new')}>
                <span className="e__btn__txt">{getText('nuevo_metodo_de_pago')}</span>
            </button>
        </div>
    </>;
}