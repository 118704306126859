import { PAYMENT_METHODS } from "./constants";

export const getPaymentMethodsTypes = async (periodicity) => {
    const defaultMethods = [PAYMENT_METHODS.CARD, PAYMENT_METHODS.PAYPAL];

    const rules = {
        yearly: async () => {
            const continentCode = await getContinentCode();
            return continentCode === 'EU' ? [PAYMENT_METHODS.SEPA] : [];
        },
    };

    const additionalMethods = rules[periodicity] ? await rules[periodicity]() : [];
    
    return [...defaultMethods, ...additionalMethods];
};

async function getContinentCode() {
    try {
        const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
        const data = await response.json();
        return data.continent_code;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const getPaymentMethodStructure = (type) => {
    switch (type) {
        case PAYMENT_METHODS.CARD:
            return {
                card: '',
                expires: '',
                cvc: '',
                network: ''
            }
        case PAYMENT_METHODS.SEPA:
            return {
                iban: '',
                full_name: ''
            }
        default:
            return false;
    }
}

export const getDataNewPaymentMethod = async (type, data, environment) => {
    let response = '';
    switch (type) {
        case PAYMENT_METHODS.CARD:
            const radarSession = await getStripeRadarSession(environment);
            response = data.card.replaceAll(' ', '') + ',';
            const expires = data.expires.split(' / ');
            response += expires[0] + ',';
            response += '20' + expires[1] + ',';
            response += data.cvc + ',';
            response += data.network;
            if (radarSession !== false) {
                response += ',' + radarSession;
            }
            break;
        case PAYMENT_METHODS.SEPA:
            response = data.iban.replaceAll(' ', '');
            response += ',' + data.full_name;
            break;
    }
    return response;
}

export const getDataCheckNewPaymentMethod = async (type, data) => {
    let response = '';
    switch (type) {
        case PAYMENT_METHODS.CARD:
            response = data.replaceAll(' ', '');
            break;
    }
    return response;
}

export const markErrorNewPaymentMethodFields = (type) => {
    let errors = [];
    switch (type) {
        case PAYMENT_METHODS.CARD:
            errors = [
                {
                    section: 'payment',
                    field: 'cvc',
                    error: 'unknown'
                },
                {
                    section: 'payment',
                    field: 'card',
                    error: 'unknown'
                },
                {
                    section: 'payment',
                    field: 'expires',
                    error: 'unknown'
                }
            ];
            break;
    }
    return errors;
}

export const validateNewPaymentMethod = (type, data) => {
    const errors = [];
    switch (type) {
        case PAYMENT_METHODS.CARD:
            if (data.cvc == '') {
                errors.push({
                    section: 'payment',
                    field: 'cvc',
                    error: 'empty'
                });
            } else {
                if (data.cvc.length < 3) {
                    errors.push({
                        section: 'payment',
                        field: 'cvc',
                        error: 'bad'
                    });
                }
            }
            if (data.card == '') {
                errors.push({
                    section: 'payment',
                    field: 'card',
                    error: 'empty'
                });
            }
            if (data.expires == '') {
                errors.push({
                    section: 'payment',
                    field: 'expires',
                    error: 'empty'
                });
            } else {
                const expires = data.expires.split(' / ');
                if ((expires.length != 2) || (expires[0].length != 2) || (expires[1].length != 2) || (expires[0] > 12)) {
                    errors.push({
                        section: 'payment',
                        field: 'expires',
                        error: 'bad'
                    });
                }
            }
            break;
        case PAYMENT_METHODS.SEPA:
            if (data.iban == '') {
                errors.push({
                    section: 'payment',
                    field: 'iban',
                    error: 'empty'
                });
            }
            if (data.full_name == '') {
                errors.push({
                    section: 'payment',
                    field: 'full_name',
                    error: 'empty'
                });
            }
            if (data.iban.replaceAll(' ', '') > 34) {
                errors.push({
                    section: 'payment',
                    field: 'iban',
                    error: 'bad'
                });
            }
            break;
    }
    return errors;
}

const getStripeRadarSession = async (environment) => {
    let pk;
    if (environment == 'educaplay.com') {
        pk = 'pk_live_51L6BqVG6FHloMrGc07pthfgXpeWBQUA0a5zHcbpgQLhYYv0cjrTigztqBLcT1finf9CQ8SYAGBvlM0hZioQaQbD900p7rc97om';
    } else {
        pk = 'pk_test_51L6BqVG6FHloMrGcSP4bSAqjVgPKnmGbAsRVscxQlvBdvquL46eOsmcyX7WjrlLtcQxR6DBcLlpmeHREFvWm0xpd00ZBlznjzs';
    }
    const stripe = Stripe(pk);
    const { radarSession, error } = await stripe.createRadarSession();
    if (error) {
        return false;
    } else {
        return radarSession.id;
    }
}