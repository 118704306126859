import { useCallback, useContext} from 'react';
import { InputText } from '../inputs/InputText';
import { MainContext } from '../../contexts/main';

export const BlockSummaryPaymentExtraData_sepa_debit = () => {

    const {  mainReducer } = useContext( MainContext );

    const handleSave = useCallback( ( value, field ) => {
        const newData = {...mainReducer.state.newPaymentMethod};
        newData.data[field] = value;
        const action = {
            type: 'changeNewPaymentMethod',
            payload: newData
        };
        mainReducer.dispatch(action);
    }, [ mainReducer ]);

    
    return  <div className="e__plns__opt__pay__wpr__item__info">
                <div className="e__form">
                    <InputText  mask={ "aa99 9999 9999 9999 9999 9999 9999 9999" } field="iban" section="payment" value={ mainReducer.state.newPaymentMethod.data.iban } handleSave={ handleSave } />
                    <InputText  field="full_name" section="payment"  value={ mainReducer.state.newPaymentMethod.data.name } handleSave={ handleSave } maxLength={100}/>
                </div> 
            </div>;
        
}