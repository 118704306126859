import { useEffect, useContext, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { checkPaymentMethod, getBilling, getCountries, getPaymentMethods } from '../../helpers/api';
import { MainRouter } from '../../routers/main';
import { ScreenError } from './ScreenError';
import { ScreenLoading } from './ScreenLoading';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const ScreenControllerMain= () => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const [ stateLoading, setStateLoading ] = useState(true);

    useEffect( () => {
        getBilling({
            tokenID: environment.tokenID,
            environment: environment.entorno,
            budget: environment.budget
        }).then(billingData => {
            if (billingData === false) {
                appReducer.dispatch({
                    type: 'setError',
                    payload: 'carga'
                });
            } else {
                mainReducer.dispatch({
                    type: 'setBillingData',
                    payload: billingData,
                    environment: environment
                });
            }
        });
        getCountries({
            environment: environment.entorno,
            lang: environment.lang
        }).then(data => {
            mainReducer.dispatch({
                type: 'setCountries',
                payload: {
                    countries: data.countries,
                    provinces: data.provinces
                }
            });
        });
    }, []);

    useEffect( () => {
        if ((mainReducer.state.selectedLicense.payer_email !== undefined) && (mainReducer.state.selectedLicense.payer_email != '')) {
            const data = {
                environment: environment.entorno,
                email: mainReducer.state.selectedLicense.payer_email
            }
            getPaymentMethods(data).then(paymentMethods => {
                const action = {
                    type: 'setPaymentMethods',
                    payload: paymentMethods
                };
                mainReducer.dispatch(action);
            });
        }
    }, [ mainReducer.state.selectedLicense.payer_email ]);

    useEffect( () => {
        if ((mainReducer.state.currentPaymentMethodNeedsCheck) && (mainReducer.state.paymentMethods !== false)) {
            let validPaymentMethod = false;
            for (const paymentMethod of mainReducer.state.paymentMethods) {
                if (paymentMethod.id == mainReducer.state.currentPaymentMethod.id) {
                    validPaymentMethod = true;
                    break;
                }
            }
            if (validPaymentMethod) {
                mainReducer.dispatch({
                    type: 'verifyCurrentPaymentMethod',
                    payload: mainReducer.state.currentPaymentMethod.status
                });
            } else {
                const data = {
                    environment: environment.entorno,
                    paymentMethod: mainReducer.state.currentPaymentMethod.id
                }
                checkPaymentMethod(data).then(paymentMethod => {
                    let status = paymentMethod.status;
                    if (status === false) {
                        status = 'error';
                    }
                    mainReducer.dispatch({
                        type: 'verifyCurrentPaymentMethod',
                        payload: status
                    });
                });
            }
        }
    }, [ mainReducer.state.paymentMethods, mainReducer.state.currentPaymentMethod, mainReducer.state.currentPaymentMethodNeedsCheck ]);

    useEffect( () => {
        if (mainReducer.state.selectedLicense !== false) {
            if ((environment.email != '') && (mainReducer.state.selectedLicense.payer_email == '')) {
                mainReducer.dispatch({
                    type: 'setPayerEmail',
                    payload: environment.email
                });
            }
            setStateLoading(false);
        }
    }, [ mainReducer.state.selectedLicense ]);

    if (appReducer.state.error !== false) {
        return <ScreenError />;
    } else {
        if (stateLoading) {
            return <ScreenLoading />;
        } else {
            return <MainRouter />;
        }
    }
    
}