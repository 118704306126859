import { getText } from '../../helpers/language';
import { SVG_star_green } from '../elements/SVG';

export const BlockTitle = ({ title }) => {

    return  <>
            <div className="e__hd">
                <div className="e__hd__wrp">
                    <div className="e__hd__hdg">
                        <SVG_star_green />
                        { getText(title) }
                    </div>
                </div>
            </div>
            </>;
}