export const PAYMENT_METHODS = {
    PAYPAL: "paypal",
    SEPA: "sepa_debit",
    CARD: "stripe_card",
};

export const PAYMENT_STATUS = {
    SUCCESS: "success",    
    PROCESSING: "processing",
    PENDING: "pending",
};