import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from "../../helpers/language";
import { SVG_check, SVG_star } from "../elements/SVG"


export const BlockTeamPlan = (props) => {

    const { onClick } =  props;
     const { mainReducer } = useContext(MainContext);
    
    return <article className="e__plns__lst__plan e__plns__lst__plan--4">
        <div className="e__plns__lst__plan__badge">{getText('teams_plan_badge')}</div>
        <div className="e__plns__hdg__hd">
            <span className="e__plns__hdg__hd__ico"><SVG_star /></span>
            <div className="e__plns__hdg__hd__main">
                <div className="e__plns__hdg__hd__shdg">{getText('teams_plan_title')}</div>
                <div className="e__plns__hdg__hd__wrp">
                    <span className="e__plns__hdg__hd__hdg">{getText('teams_plan_subtitle')}</span>
                </div>
            </div>
        </div>
        <div className="e__plns__lst__plan__slogan">
            <span className="e__plns__lst__plan__slogan__hd">{getText('teams_plan_slogan_title')}</span>
            <span className="e__plns__lst__plan__slogan__txt">{getText('teams_plan_slogan_description')}</span>
        </div>
        <div className="e__plns__lst__plan__info">
            <div className="e__plns__lst__plan__info__item">
                <span className="e__plns__lst__plan__info__item__ico"><SVG_check /></span>
                <span className="e__plns__lst__plan__info__item__txt">{getText('teams_plan_info1')}</span>
            </div>
            <div className="e__plns__lst__plan__info__item">
                <span className="e__plns__lst__plan__info__item__ico"><SVG_check /></span>
                <span className="e__plns__lst__plan__info__item__txt">{getText('teams_plan_info2')}</span>
            </div>
            <div className="e__plns__lst__plan__info__item">
                <span className="e__plns__lst__plan__info__item__ico"><SVG_check /></span>
                <span className="e__plns__lst__plan__info__item__txt">{getText('teams_plan_info3')}</span>
            </div>
            <div className="e__plns__lst__plan__info__item">
                <span className="e__plns__lst__plan__info__item__ico"><SVG_check /></span>
                <span className="e__plns__lst__plan__info__item__txt">{getText('teams_plan_info4')}</span>
            </div>
        </div>
        <div className="e__plns__vlue">
            <div className="e__plns__vlue__iva e__plns__vlue__iva--hlght">
                <div className="e__plns__vlue__iva__txt">
                    <span className="e__plns__vlue__iva__txt__tot">
                        {getText('teams_plan_price_title_monthly')}
                    </span>
                </div>
                <div className="e__plns__vlue__iva__info">
                    <span className="e__plns__vlue__iva__txt">{getText("teams_plan_price_since")}</span>
                    <span className="e__plns__vlue__end__info__nmbr">20,00</span>
                    <span className="e__plns__vlue__end__info__coin">{mainReducer.state.currency.code}</span>
                </div>
            </div>
            <div className="e__plns__vlue__ttl">
                <div className="e__plns__vlue__ttl__txt">
                    <span className="e__plns__vlue__ttl__txt__tot">{getText("teams_plan_price_title_yearly")}</span>
                </div>
                <div className="e__plns__vlue__ttl__info">
                    <span className="e__plns__vlue__ttl__txt">{getText("teams_plan_price_since")}</span>
                    <span className="e__plns__vlue__end__info__nmbr">240,00</span>
                    <span className="e__plns__vlue__end__info__coin">{mainReducer.state.currency.code}</span>
                </div>
            </div>
        </div>
        <div className="e__plns__lst__plan__btns">
            <button className="e-btn e-btn__primary" onClick={onClick}>
                <span className="e__btn__txt">{getText('teams_plan_buy_title')}</span>
            </button>
        </div>
    </article>


}
