import { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { generateBudget } from '../../helpers/api';
import { isPayable } from '../../helpers/billing';
import { getText } from '../../helpers/language';
import { navigateTo } from '../../helpers/urls';
import { BlockBack } from '../blocks/BlockBack';
import { BlockDownloadBudget } from '../blocks/BlockDownloadBudget';
import { BlockInvoices } from '../blocks/BlockInvoices';
import { BlockNotifications } from '../blocks/BlockNotifications';
import { BlockPayAction } from '../blocks/BlockPayAction';
import { BlockPayForMe } from '../blocks/BlockPayForMe';
import { BlockSummaryBilling } from '../blocks/BlockSummaryBilling';
import { BlockSummaryOptions } from '../blocks/BlockSummaryOptions';
import { BlockSummaryPayment } from '../blocks/BlockSummaryPayment';
import { BlockTitle } from '../blocks/BlockTitle';
import { BlockViewUser } from '../blocks/BlockViewUser';
import { v4 as uuid } from 'uuid';
import { ModalLicense } from '../modals/ModalLicense';
import { BlockInfoLicense } from '../blocks/BlockInfoLicense';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const ScreenDataMain = ({ mode, handleBuy, handleCancel }) => {

    const { environment, mainReducer, appReducer } = useContext(MainContext);

    const [stateVisibleBudget, setStateVisibleBudget] = useState(false);

    const idNotificationBadPaymentMethod = useRef(false);

    useEffect(() => {
        if (mode == 'budget') {
            appReducer.dispatch({
                type: 'setNotifications',
                payload: [
                    {
                        type: 'info',
                        content: <>{getText('metodos_de_pago_text')} <button className="e__lnk" onClick={handleGoToCheckout} >{getText('pagar_ahora_presupuesto')}</button></>
                    }
                ]
            });
            mainReducer.dispatch({
                type: 'changeWantsAnInvoice',
                payload: true
            });
        }
    }, [mode]);

    useEffect(() => {
        if (mode != 'budget') {
            if ((idNotificationBadPaymentMethod.current !== false) && (mainReducer.state.currentPaymentMethod !== false) && (mainReducer.state.currentPaymentMethod.status != PAYMENT_STATUS.SUCCESS) && ((mainReducer.state.selectedPaymentMethod !== mainReducer.state.currentPaymentMethod.id) || (mainReducer.state.newPaymentMethod !== false))) {
                appReducer.dispatch({
                    type: 'deleteNotification',
                    payload: idNotificationBadPaymentMethod.current
                });
                idNotificationBadPaymentMethod.current = false;
            }
        }
    }, [mainReducer.state.selectedPaymentMethod, mainReducer.state.newPaymentMethod]);

    useEffect(() => {
        if (mode != 'budget') {
            const paymentMethod = mainReducer.state.currentPaymentMethod;
            if (paymentMethod !== false && paymentMethod.status != PAYMENT_STATUS.SUCCESS && paymentMethod.status != PAYMENT_STATUS.PROCESSING) {
                idNotificationBadPaymentMethod.current = uuid();
                appReducer.dispatch({
                    type: 'setNotifications',
                    payload: [
                        {
                            id: idNotificationBadPaymentMethod.current,
                            type: 'error',
                            content: getText('forma_de_pago_erronea')
                        }
                    ]
                });
            }
        }
    }, [mode, mainReducer.state.currentPaymentMethod]);

    useEffect(() => {
        if (isPayable(mainReducer)) {
            setStateVisibleBudget(true);
        } else {
            setStateVisibleBudget(false);
        }
    }, [mainReducer.state.selectedLicense, mainReducer.state.currentLicense, mainReducer.state.selectedPaymentMethod, mainReducer.state.currentPaymentMethod, mainReducer.state.newPaymentMethod]);

    const navigate = useNavigate();

    useEffect(() => {
        if (stateVisibleBudget) {
            if (mainReducer.state.budgetGenerated === false) {
                const data = {
                    selectedLicense: mainReducer.state.selectedLicense,
                    environment: environment.entorno,
                    user: mainReducer.state.user.id,
                    budget: environment.budget
                };
                generateBudget(data).then(budgetData => {
                    mainReducer.dispatch({
                        type: 'setBudgetGenerated',
                        payload: budgetData
                    });
                });
            }
        }
    }, [mainReducer.state.budgetGenerated, stateVisibleBudget]);

    const handleGoToCheckout = () => {
        navigateTo('premiumCheckout', navigate, environment, appReducer);
    }

    const handleBack = () => {
        navigateTo('premium', navigate, environment, appReducer);
    }

    return <>
        <main className="e__plns__opt">
            {((mainReducer.state.budget === false) && (mode != 'edit')) &&
                <BlockBack title="planes" onBack={handleBack} />
            }
            <BlockTitle title={(mode == 'budget') ? 'presupuesto' : (mode == 'edit') ? 'plan' : 'opciones_de_pago'} />
            <BlockNotifications />
            <div className="e__plns__opt__main">
                {(mode != 'budget') &&
                    <BlockPayAction handleBuy={handleBuy} />
                }
                <div className="e__plns__opt__main__wpr">
                    {(mainReducer.state.user.id != mainReducer.state.realUser.id) &&
                        <BlockViewUser />
                    }
                    <div className="e__plns__opt__main__wpr__plan">
                        {((mode == 'edit') || ((mode == 'standard') && (mainReducer.state.user.id != mainReducer.state.realUser.id))) &&
                            <BlockSummaryOptions mode={mode} handleCancel={handleCancel} />
                        }
                        {(mode == 'budget') ?
                            <BlockInfoLicense license={mainReducer.state.selectedLicense} />
                            :
                            <BlockSummaryPayment />
                        }
                        <BlockSummaryBilling />
                        {(mode == 'edit') &&
                            <BlockInvoices />
                        }
                    </div>
                    {(stateVisibleBudget) &&
                        <>
                            {((mode != 'standard') && (mainReducer.state.selectedLicense.id != mainReducer.state.currentLicense.id) || (mainReducer.state.selectedLicense.periodicity != mainReducer.state.currentLicense.periodicity) || (mainReducer.state.selectedLicense.licenses != mainReducer.state.currentLicense.licenses)) &&
                                <BlockDownloadBudget />
                            }
                            <BlockPayForMe />
                        </>
                    }
                </div>
            </div>
            {(appReducer.state.modals.license) && <ModalLicense />}
        </main>
    </>;

}