import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { SVG_paymentMethod } from '../elements/SVG';

export const BlockPayment = ({ payment }) => {

    const { mainReducer, environment } = useContext( MainContext );

    return  <>  
                {((mainReducer.state.realUser === false) || ((mainReducer.state.realUser !== false) && (mainReducer.state.realUser.email == mainReducer.state.currentPaymentMethod.payer))) ?
                    <>
                        <span className="e__plns__opt__pay__wpr__save__ico">
                            <SVG_paymentMethod type={payment.type} />
                        </span>             
                        <div className="e__plns__opt__pay__info__wpr">
                            <span className="e__plns__opt__pay__info__hd">{ getText('payment_method_title_' + payment.type) }</span>
                            <span className="e__plns__opt__pay__info__nmbr">{ getText('payment_method_prefix_' + payment.type) } { payment.description }</span>
                        </div>
                        {(payment.expires !== false) &&
                            <div className="e__plns__opt__pay__info__date">{ getText('caduca')}: { tsToDate( payment.expires, environment.lang , 'paymentMethod') }</div>
                        }
                    </>
                :
                    <>
                        <span className="e__plns__opt__pay__wpr__save__ico">
                            <SVG_paymentMethod type="payer" />
                        </span>                                   
                        <div className="e__plns__opt__pay__info__wpr">
                            <span className="e__plns__opt__pay__info__hd">{ getText('payment_method_title_payer') }</span>
                            <span className="e__plns__opt__pay__info__nmbr">{ mainReducer.state.currentPaymentMethod.payer }</span>
                        </div>
                    </>
                }
            </>;
}