import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { SVG_alert, SVG_atention, SVG_okcheck, SVG_send } from '../elements/SVG';

export const BlockNotification = ({ notification }) => {

    const { appReducer } = useContext( MainContext );

    const IconSVG = () => {
        switch (notification.type) {
        case 'error':
            return <SVG_alert />;
        case 'ok':
            return <SVG_okcheck />;
        case 'send':
            return <SVG_send />;
        default:
            return <SVG_atention />;
        }
    }

    const handleClose = () => {
        appReducer.dispatch({
            type: 'deleteNotification',
            payload: notification.id
        });
    }

    return  <>
                <div className={`e__notice e__notice--${notification.type}`}>
                    <button className="e__btn e__btn__close" onClick={ handleClose }>X</button>
                    <span className="e__notice__ico">
                        <IconSVG />
                    </span>
                    <div className="e__notice__wpr">
                        <span className="e__notice__txt">{ notification.content }</span>
                        <span className="e__notice__btn"></span>
                    </div>
                </div>
            </>;
}