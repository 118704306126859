import { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { getURL, navigateTo } from '../../helpers/urls';
import config from '../../resources/config.json';
import { SVG_check, SVG_close, SVG_star } from '../elements/SVG';

export const BlockCompare = () => {

    const { mainReducer, environment, appReducer } = useContext( MainContext );

    const navigate = useNavigate();

    const handleSelectPlan = ( id ) => {
        const action = {
            type: 'changeIdLicense',
            payload: id
        };
        mainReducer.dispatch(action);
        navigateTo('premiumCheckout', navigate, environment, appReducer);
    }

    const handleKnowMore = ( id ) => {
        appReducer.dispatch({
            type: 'openModal',
            payload: {
                id: 'video',
                value: config.plans[id].knowMore
            }
        });
    }

    return  <>
            <section className="e__plns__lst__tbl" id="sectionPriceTable">
                <section className="e__plns__lst__tbl__wpr">
                    <table className="e__plns__lst__tbl__box" width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <caption className="e__plns__lst__tbl__hd"><div>{ getText('comparar_title') }</div></caption>
                        <thead>
                            <tr className="e__plns__lst__tbl__plan">
                                {/*  Accesibilidades */}
                                <th className="e__plns__lst__tbl__plan__mpty" aria-label={getText('comparacion_en_forma_de_tabla')}></th>               
                                {Object.keys(config.plans).map( index =>
                                <th key={index} className={`e__plns__lst__tbl__plan__hd e__plns__lst__tbl__plan__hd--${index}${(config.plans[index].highlight) ? ' e__plns__lst__tbl__plan__hd--highlight' : ''}`} width="17.5%">
                                    <div>
                                        <div className="e__plns__hdg__hd">
                                            <span className="e__plns__hdg__hd__ico"><SVG_star /></span>
                                            <div className="e__plns__hdg__hd__main">
                                                <div className="e__plns__hdg__hd__shdg">{ getText('plan_educaplay') }</div>
                                                <div className="e__plns__hdg__hd__wrp">
                                                    <span className="e__plns__hdg__hd__hdg">{ getText('plan_name_' + index) }</span>
                                                </div>
                                            </div>
                                        </div>                                   
                                        <div className="e__plns__lst__tbl__plan__hd__sname">{ getText('plan_description_' + index) }</div>
                                        {((mainReducer.state.user === false) && (index == 0)) ?
                                                <a className="e-btn e-btn__secondary" href={ getURL(environment,'signup') + (environment.nc ? '?nc=true' : '') }>
                                                    {getText('registrate_button')}
                                                </a>
                                            :
                                                ((mainReducer.state.user !== false) && (((mainReducer.state.currentLicense.periodicity == mainReducer.state.selectedLicense.periodicity) && (mainReducer.state.currentLicense.licenses == mainReducer.state.selectedLicense.licenses) && (mainReducer.state.currentLicense.id == index)) || ((mainReducer.state.currentLicense.id == 0) && (index == 0)))) ?
                                                <span className="e__txt">{ getText('plan_contratado') }</span>
                                                :
                                                    (index != 0) &&
                                                        <button className="e-btn e-btn__primary" onClick={ () => handleSelectPlan(index) }>
                                                            <span className="e__btn__txt">{ getText('elegir') }</span>
                                                        </button>
                                        }
                                        { (index > 0) &&
                                            <button className="e__lnk" onClick={ () => handleKnowMore( index ) }>{ getText('saber_mas') }</button>
                                        }
                                    </div>
                                </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {config.features.map( feature =>
                                <Fragment key={ feature.title }>
                                <tr className="e__plns__lst__tbl__row">
                                    <td className="e__plns__lst__tbl__row__hd" colSpan={ Object.keys(config.plans).length + 1 }>{ getText('feature_group_' + feature.title) }</td>
                                </tr>
                                {feature.elements.map( element =>
                                    <tr key={ feature.title + '_' + element } className="e__plns__lst__tbl__row">
                                        <td className="e__plns__lst__tbl__row__feature">
                                            <div className="e__plns__lst__tbl__feature">{ getText('feature_element_' + element) }</div>
                                            { (getText('placeholder_feature_element_' + element) != '') && 
                                                <span className="e__btn__info">
                                                    <button className="e__btn" aria-label={ getText('mas_informacion') }title={ getText('mas_informacion') }>i</button>
                                                    <span className="e__btn__info__wp">{ getText('placeholder_feature_element_' + element) }</span>
                                                </span>
                                            }
                                        </td>
                                        {Object.keys(config.plans).map( index =>
                                            <td key={ feature.title + '_' + element + '_' + index } className="e__plns__lst__tbl__cell" align="center">
                                                { (config.plans[index].features[element] === true) ?
                                                    <span className="e__ico">
                                                        <SVG_check />
                                                    </span>
                                                :
                                                    (config.plans[index].features[element] === false) ?
                                                        <span className="e__ico">
                                                            <SVG_close />
                                                        </span>
                                                    :
                                                        getText(config.plans[index].features[element])
                                                }
                                            </td>
                                        )}
                                    </tr>
                                )}
                                </Fragment>
                            )}
                        </tbody>
                        <tfoot className="e__plns__lst__tbl__foot">
                            <tr className="e__plns__lst__tbl__foot__row">
                                <td className="e__plns__lst__tbl__foot__row__hd">
                                </td>
                                {Object.keys(config.plans).map( index =>
                                <th key={index} className="e__plns__lst__tbl__plan__hd">
                                    <div>
                                        <div className="e__plns__lst__tbl__plan__hd__sname">{ getText('plan_description_' + index) }</div>
                                        {((mainReducer.state.currentLicense.periodicity == mainReducer.state.selectedLicense.periodicity) && (mainReducer.state.currentLicense.licenses == mainReducer.state.selectedLicense.licenses) && (mainReducer.state.currentLicense.id == index)) ?                                           
                                            <span className="e__txt">{ getText('plan_contratado') }</span>
                                        :
                                            (index != 0) &&
                                            <button className="e__btn e__btn__prmry" onClick={ () => handleSelectPlan(index) }>
                                                <span className="e__btn__txt">{ getText('elegir') }</span>
                                            </button>
                                        }
                                        { (index > 0) &&
                                            <button className="e__lnk" onClick={ () => handleKnowMore( index ) }>{ getText('saber_mas') }</button>
                                        }
                                    </div>
                                </th>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </section>
            </section>
            </>;
}