import { useContext, useState, useEffect, forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { SVG_xcircle } from '../elements/SVG';

export const InputText = forwardRef(({ field, section, keyId = false, value = '', handleOnEnterPress = false, handleSave, showLabel = true , mask = false, onChange = false, type='text', maxLength =  null }, ref) => {
 
    const { appReducer } = useContext( MainContext );

    const [ stateError, setStateError ] = useState(false);
    const [ stateValue, setStateValue ] = useState(value);

    useEffect( () => {
        let displayError = false;
        appReducer.state.fieldErrors.forEach( ( error ) => {
            if ((error.section == section) && (error.field == field)) {
                displayError = error.error;
            }
        });
        if (displayError === false) {
            setStateError(false);
        } else {
            setStateError(displayError);
        }
    }, [ appReducer.state.fieldErrors ]);

    useEffect( () => {
        setStateValue(value);
    }, [ value ]);

    const handleChange = ({ target }) => {
        setStateValue(target.value);
        if (onChange !== false) {           
            onChange(target.value);
        }
        if (stateError !== false) {
            appReducer.dispatch({
                type: 'deleteFieldError',
                payload: {
                    section: section,
                    field: field,
                    error: stateError
                }
            });
        }
    }

    const handleSaveLocal = () => {
        if (value != stateValue) {
            handleSave( stateValue, field, section, keyId );
        }
    }

    const handleOnEnterPressLocal = ( event ) => {
        if (event.which == 13) {
            if (handleOnEnterPress !== false) {
                handleSaveLocal();
                handleOnEnterPress();
            }
        }
    }

    return  <>
                <div className={`e__form__item${(stateError === false) ? '' : ' rrr'}`}>
                    {(showLabel) && 
                        <label htmlFor={( keyId === false) ? `field_${section}_${field}` : `field_${section}_${field}_${keyId}`}>{ getText('field_' + section + '_' + field + '_name') }</label>
                    }
                    {(mask === false) ?
                        <input onKeyPress={ handleOnEnterPressLocal } ref={ ref } type={ type } id={( keyId === false) ? `field_${section}_${field}` : `field_${section}_${field}_${keyId}`} placeholder={ getText('field_' + section + '_' + field + '_placeholder') } value={ stateValue } onChange={ handleChange } onBlur={ handleSaveLocal } maxLength={maxLength} />
                    :
                        <InputMask onKeyPress={ handleOnEnterPressLocal } ref={ ref } maskChar="" mask={mask} type="text" id={( keyId === false) ? `field_${section}_${field}` : `field_${section}_${field}_${keyId}`} placeholder={ getText('field_' + section + '_' + field + '_placeholder') } value={ stateValue } onChange={ handleChange } onBlur={ handleSaveLocal } maxLength={maxLength}/>
                    }
                    {((stateError !== false) && (stateError != 'unknown')) &&
                        <span className="e__txt__error">{ getText('error_field_' + stateError) }</span>
                    }
                    {stateError !== false &&
                        <span className="e__form__item__ico">
                            <SVG_xcircle />
                        </span>
                    }
                </div>
            </>;

});