import { deepSpread } from "../helpers/functions";
import { getRates, getDiscountForPeriodicities } from "../helpers/billing";

export const mainReducer = ( state, action ) => {
    switch (action.type) {
    case 'changePeriodicity':
        if (action.payload != state.selectedLicense.periodicity) {
            const selectedLicensechangePeriodicity =  {
                ...state.selectedLicense,
                periodicity: action.payload
            };
            return {
                ...state,
                selectedLicense: selectedLicensechangePeriodicity,
                ratesApplied: getRates(selectedLicensechangePeriodicity, state.inProgressLicense, state.rates, state.currency.decimals, state.credit, state.minimumAmount, state.budget),
                budgetGenerated: false
            };
        } else {
            return state;
        }
    case 'changeIdLicense':
        if (action.payload != state.selectedLicense.id) {
            return {
                ...state,
                selectedLicense: {
                    ...state.selectedLicense,
                    id: parseInt(action.payload)
                },
                budgetGenerated: false
            };
        } else {
            return state;
        }
    case 'setBudgetGenerated': {
        if (action.payload === false) {
            return {
                ...state,
                budgetGenerated: false
            };
        } else {
            const setBudgetGeneratedVars = [];
            setBudgetGeneratedVars['selectedLicense'] = {
                ...state.selectedLicense,
                vat: action.payload.vat
            };
            return {
                ...state,
                budgetGenerated: action.payload.code,
                ratesApplied: getRates( setBudgetGeneratedVars['selectedLicense'], state.inProgressLicense, state.rates, state.currency.decimals, state.credit, state.minimumAmount, state.budget),
                selectedLicense: setBudgetGeneratedVars['selectedLicense']
            };
        }
    }
    case 'setPayerEmail': {
        return {
            ...state,
            selectedLicense: {
                ...state.selectedLicense,
                payer_email: action.payload
            }
        };
    }
    case 'changeWantsAnInvoice':
        return {
            ...state,
            selectedLicense: {
                ...state.selectedLicense,
                wantsAnInvoice: action.payload
            },
            budgetGenerated: false
        };
    case 'changeBillingData':        
        if ((state.selectedLicense.country != action.payload.country) && (state.provinces[action.payload.country] !== undefined) && (state.provinces[action.payload.country][action.payload.province] === undefined)) {
            action.payload.province = '';
        }
        return {
            ...state,
            selectedLicense: {
                ...state.selectedLicense,
                address: action.payload.address,
                country: action.payload.country,
                name: action.payload.name,
                province: action.payload.province,
                town: action.payload.town,
                vat_number: action.payload.vat_number,
                zip_code: action.payload.zip_code
            },
            budgetGenerated: false
        };
    case 'setCountries':
        return {
            ...state,
            countries: action.payload.countries,
            provinces: action.payload.provinces
        };
    case 'setInvoices':
        return {
            ...state,
            invoices: action.payload
        };
    case 'setPaymentMethods':
        const setPaymentMethodsVars = [];
        setPaymentMethodsVars['selectedPaymentMethod'] = state.selectedPaymentMethod;
        setPaymentMethodsVars['currentPaymentMethodNeedsCheck'] = state.currentPaymentMethodNeedsCheck;
        if ((state.currentPaymentMethod === false) || ((state.currentPaymentMethod.payer != state.selectedLicense.payer_email) && ((state.realUser === false) || (state.realUser.email != state.user.email)))) {
            if (action.payload.length > 0) {
                setPaymentMethodsVars['selectedPaymentMethod'] = action.payload[0].id;
            } else {
                setPaymentMethodsVars['selectedPaymentMethod'] = false;
            }
            setPaymentMethodsVars['currentPaymentMethodNeedsCheck'] = false;
        }
        return {
            ...state,
            paymentMethods: action.payload,
            currentPaymentMethodNeedsCheck: setPaymentMethodsVars['currentPaymentMethodNeedsCheck'],
            selectedPaymentMethod: setPaymentMethodsVars['selectedPaymentMethod']
        };
    case 'changePaymentMethod':
        return {
            ...state,
            selectedPaymentMethod: action.payload
        };
    case 'addPaymentMethod':
        const paymentMethodsAdd = [ ...state.paymentMethods ];
        paymentMethodsAdd.unshift(action.payload);
        return {
            ...state,
            paymentMethods: paymentMethodsAdd,
            newPaymentMethod: false,
            selectedPaymentMethod: action.payload.id
        };
    case 'changeNewPaymentMethod':
        return {
            ...state,
            newPaymentMethod: action.payload
        };
    case 'verifyCurrentPaymentMethod':

        const currentPaymentMethod = {
            ...state.currentPaymentMethod,
            status: action.payload
        };
        return {
            ...state,
            currentPaymentMethod: currentPaymentMethod,
            currentPaymentMethodNeedsCheck: false
        };
    case 'setAcceptedConditions':
        return {
            ...state,
            acceptedConditions: action.payload
        };
    case 'invalidateBillingData':
        return {
            ...state,
            loadingBillingData: true
        };
    case 'setBillingData':
        const setBillingDataVars = [];
        setBillingDataVars['selectedLicense'] = deepSpread(action.payload.currentLicense);
        if (action.payload.realUser === false) {
            setBillingDataVars['selectedLicense'].payer_email = '';
        } else {
            setBillingDataVars['selectedLicense'].payer_email = action.payload.realUser.email;
        }
        if ((action.payload.budget === false) || (action.payload.budget.accomplished === true)) {
            if (action.environment.license.id > 0) {               
                setBillingDataVars['selectedLicense'] = {
                    ...setBillingDataVars['selectedLicense'],
                    id: action.environment.license.id,
                    periodicity: action.environment.license.periodicity,
                    licenses: action.environment.license.licenses
                };
            }
        } else {
            setBillingDataVars['selectedLicense'] = {
                ...setBillingDataVars['selectedLicense'],
                id: action.payload.budget.license,
                periodicity: action.payload.budget.periodicity,
                licenses: action.payload.budget.licenses,
                wantsAnInvoice: action.payload.budget.wantsAnInvoice,
                vat_number: action.payload.budget.vat_number,
                name: action.payload.budget.name,
                address: action.payload.budget.address,
                town: action.payload.budget.town,
                province: action.payload.budget.province,
                zip_code: action.payload.budget.zip_code,
                country: action.payload.budget.country,
                vat: action.payload.budget.vat
            };
        }
        if (action.payload.currentPaymentMethod === false) {
            setBillingDataVars['selectedPaymentMethod'] = false;
            setBillingDataVars['currentPaymentMethodNeedsCheck'] = false;
        } else {
            setBillingDataVars['selectedPaymentMethod'] = action.payload.currentPaymentMethod.id;
            setBillingDataVars['currentPaymentMethodNeedsCheck'] = true;
        }
        if (action.environment.budget != '') {
            setBillingDataVars['budgetGenerated'] = action.environment.budget;
        } else {
            setBillingDataVars['budgetGenerated'] = state.budgetGenerated;
        }
        return {
            ...state,
            loadingBillingData: false,
            user: action.payload.user,
            realUser: action.payload.realUser,
            currency: action.payload.currency,
            currentLicense: action.payload.currentLicense,
            inProgressLicense: action.payload.inProgressLicense,
            subLicense: action.payload.subLicense,
            currentPaymentMethod: action.payload.currentPaymentMethod,
            currentPaymentMethodNeedsCheck: setBillingDataVars['currentPaymentMethodNeedsCheck'],
            selectedPaymentMethod: setBillingDataVars['selectedPaymentMethod'],
            credit: action.payload.credit,
            selectedLicense: setBillingDataVars['selectedLicense'],
            discounts: action.payload.discounts,
            rates: action.payload.rates,
            minimumAmount: action.payload.minimumAmount,
            discountForPeriodicity: getDiscountForPeriodicities(action.payload.rates),
            ratesApplied: getRates( setBillingDataVars['selectedLicense'], action.payload.inProgressLicense, action.payload.rates, action.payload.currency.decimals, action.payload.credit, action.payload.minimumAmount, action.payload.budget ),
            EU: action.payload.EU,
            budget: action.payload.budget,
            budgetGenerated: setBillingDataVars['budgetGenerated']
        };
    default:
        return state;
    }
}

export const getMainInitialState = () => {
    return {
        loadingBillingData: true,
        user: false,
        realUser: false,
        currency: false,
        currentLicense: false,
        inProgressLicense: false,
        subLicense: false,
        currentPaymentMethod: false,
        currentPaymentMethodNeedsCheck: false,
        selectedPaymentMethod: false,
        newPaymentMethod: false,
        paymentMethods: false,
        credit: 0,
        selectedLicense: false,
        discounts: false,
        rates: false,
        minimumAmount: 0,
        ratesApplied: false,
        discountForPeriodicity: false,
        EU: false,
        budget: false,
        budgetGenerated: false,
        countries: false,
        provinces: false,
        invoices: false,
        acceptedConditions: false
    };
}