import { useState, useContext, useEffect } from 'react';
import { BlockSummaryPaymentCollapsed } from './BlockSummaryPaymentCollapsed';
import { BlockSummaryPaymentNew } from './BlockSummaryPaymentNew';
import { BlockSummaryPaymentSaved } from './BlockSummaryPaymentSaved';
import { getText } from '../../helpers/language';
import { MainContext } from '../../contexts/main';
import { BlockLoading } from './BlockLoading';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const BlockSummaryPayment = () => {

    const { mainReducer } = useContext( MainContext );

    const initialStateScreen = () => {
        if ((mainReducer.state.paymentMethods === false) || (mainReducer.state.currentPaymentMethodNeedsCheck)) {
            return 'loading';
        } else {
            if ((mainReducer.state.selectedPaymentMethod === false) || (mainReducer.state.newPaymentMethod !== false)) {
                return 'new';
            } else {
                if ((mainReducer.state.currentPaymentMethod !== false) && (mainReducer.state.currentPaymentMethod.id == mainReducer.state.selectedPaymentMethod) && (mainReducer.state.currentPaymentMethod.status == PAYMENT_STATUS.SUCCESS)) {
                    return 'collapsed';
                } else {
                    return 'saved';
                }
            }
        }
    }

    const [ stateScreen, setStateScreen ] = useState('loading');

    useEffect( () => {
        if (stateScreen === 'loading') {
            setStateScreen(initialStateScreen());
        }
    }, [ mainReducer.state.paymentMethods, mainReducer.state.currentPaymentMethodNeedsCheck ]);
    return  <>           
            <fieldset className="e__plns__opt__pay">
                <legend>
                    { getText('metodo_de_pago') }
                </legend>
                {(stateScreen == 'collapsed') ? 
                    <BlockSummaryPaymentCollapsed changeScreen={ setStateScreen } />
                :
                    (stateScreen == 'saved') ?
                        <BlockSummaryPaymentSaved changeScreen={ setStateScreen } />
                    :
                        (stateScreen == 'new') ?
                            <BlockSummaryPaymentNew changeScreen={ setStateScreen } />
                        :
                            <BlockLoading />
                }
            </fieldset>
            </>;
}