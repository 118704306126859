import { useCallback, useContext, useEffect, useState } from 'react';
import { InputText } from '../inputs/InputText';
import { MainContext } from '../../contexts/main';
import { BlockCardBrands } from './BlockCardBrands';
import { getPaymentMethodData } from '../../helpers/api';
import { PAYMENT_STATUS } from '../../helpers/constants';

export const BlockSummaryPaymentExtraData_stripe_card = () => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const hasCardFailed = appReducer.state.fieldErrors.some( error => error.field === 'card' );

    const getBrand = async( cardNumber ) => {
        cardNumber = cardNumber.replace(/\s/g, "");
        const cardBrands = [
            {
                brand: 'visa',
                startsWith: [
                    { start: 4, end: 4 }
                ],
                length: [ 16 ],
                cvcLength: [ 3 ],
                checkNetwork: true
            },
            {
                brand: 'mastercard',
                startsWith: [
                    { start: 51, end: 55 },
                    { start: 22, end: 27 }
                ],
                length: [ 16 ],
                cvcLength: [ 3 ],
                checkNetwork: true
            },
            {
                brand: 'amex',
                startsWith: [
                    { start: 34, end: 34 },
                    { start: 37, end: 37 }
                ],
                length: [ 15 ],
                cvcLength: [ 3, 4 ],
                checkNetwork: false
            },
            {
                brand: 'discover',
                startsWith: [
                    { start: 60, end: 60 },
                    { start: 64, end: 65 }
                ],
                length: [ 16 ],
                cvcLength: [ 3 ],
                checkNetwork: false
            },
            {
                brand: 'diners',
                startsWith: [
                    { start: 30, end: 30 },
                    { start: 38, end: 39 }
                ],
                length: [ 16 ],
                cvcLength: [ 3 ],
                checkNetwork: false
            },
            {
                brand: 'diners',
                startsWith: [
                    { start: 36, end: 36 }
                ],
                length: [ 14 ],
                cvcLength: [ 3 ],
                checkNetwork: false
            },
            {
                brand: 'jcb',
                startsWith: [
                    { start: 35, end: 35 }
                ],
                length: [ 16 ],
                cvcLength: [ 3 ],
                checkNetwork: false
            },
            {
                brand: 'unionpay',
                startsWith: [
                    { start: 620, end: 626 },
                    { start: 628, end: 629 },
                    { start: 6270, end: 6276 },
                    { start: 6278, end: 6279 },
                    { start: 62770, end: 62777 },
                    { start: 62779, end: 62779 },
                    { start: 627781, end: 627789 },
                    { start: 81, end: 81 },
                ],
                length: [ 16, 17, 18, 19 ],
                cvcLength: [ 3 ],
                checkNetwork: false
            }
        ];
        for (let i = 0; i < cardBrands.length; i++) {
            const brand = cardBrands[i];
            for (let j = 0; j < brand.startsWith.length; j++) {
                const startRange = brand.startsWith[j];
                const cardPrefix = parseInt(cardNumber.substring(0, startRange.start.toString().length));
                if (cardPrefix >= startRange.start && cardPrefix <= startRange.end) {
                    let numberMask = '';
                    const numberMaxLength = (brand.length,Math.max(...brand.length));
                    if (numberMaxLength <= 15) {
                        numberMask = '9999 999999 9';
                        for (let i = 11; i < numberMaxLength; i++) {
                            numberMask += '9';
                        }
                    } else {
                        numberMask = '9999 9999 9999 9999';
                        if (numberMaxLength > 16) {
                            numberMask += ' ';
                            for (let i = 16; i < numberMaxLength; i++) {
                                
                                numberMask += '9';
                            }
                        }
                    }
                    const cvcMaxLength = (brand.cvcLength,Math.max(...brand.cvcLength));
                    const response = {
                        networks: [ brand.brand ],
                        masks: {
                            number: numberMask,
                            cvc: '9'.repeat(cvcMaxLength)
                        }
                    }
                    if ((brand.checkNetwork) && (brand.length.includes(cardNumber.length))) {
                        const cardData = await getPaymentMethodData({
                            environment: environment.entorno,
                            paymentMethod: {
                                type: 'stripe_card',
                                data: cardNumber
                            }
                        });
                        if ((cardData.status == PAYMENT_STATUS.SUCCESS) && cardData.data.cartes_bancaires) {
                            response.networks = [ 'cartes_bancaires', brand.brand ];
                        }
                    }
                    return response;
                }
            }
        }
        return {
            networks: [],
            masks: {
                number: '9999 9999 9999 9999 999',
                cvc: '9999'
            }
        };
    }

    const [ stateBrand, setStateBrand ] = useState({
        networks: [],
        masks: {
            number: '9999 9999 9999 9999 999',
            cvc: '9999'
        }
    });

    useEffect( () => {
        cardOnChange( mainReducer.state.newPaymentMethod.data.card );
    }, []);    
    
    const cardOnChange = useCallback( ( value ) => {
        getBrand( value ).then( brand => {
            setStateBrand( brand ); 
        });
    }, []);

    const handleSave = useCallback( ( value, field ) => {
        const newData = {...mainReducer.state.newPaymentMethod};
        newData.data[field] = value;
        const action = {
            type: 'changeNewPaymentMethod',
            payload: newData
        };
        mainReducer.dispatch(action);
    }, [ mainReducer ]);
    
    return  <div className="e__plns__opt__pay__wpr__item__info">
                <div className="e__form">
                    <InputText mask={ stateBrand.masks.number } field="card" section="payment" value={ mainReducer.state.newPaymentMethod.data.card } handleSave={ handleSave } onChange={ cardOnChange } />
                    <BlockCardBrands field="network" availableCardNetworks={ stateBrand.networks } value={ mainReducer.state.newPaymentMethod.data.network } handleSave={ handleSave } isFailed={hasCardFailed} />
                    <InputText mask="99 / 99" field="expires" section="payment" value={ mainReducer.state.newPaymentMethod.data.expires } handleSave={ handleSave } />
                    <InputText mask={ stateBrand.masks.cvc } field="cvc" section="payment" value={ mainReducer.state.newPaymentMethod.data.cvc } handleSave={ handleSave } />
                </div>
            </div>;
        
}