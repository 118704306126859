import { useContext, useState, useCallback, Fragment } from 'react';
import { MainContext } from '../../contexts/main';
import { BlockInfoLicense } from './BlockInfoLicense';
import config from '../../resources/config.json';
import { getText } from '../../helpers/language';
import { tsToDate } from '../../helpers/functions';
import { SVG_back } from '../elements/SVG';
import { getURL } from '../../helpers/urls';


export const BlockSummaryOptions = ({ handleCancel, mode }) => {

    const { mainReducer, environment } = useContext(MainContext);

    const [stateExpanded, setStateExpanded] = useState(false);

    const handleEdit = () => {
        if(mainReducer.state.currentLicense.licenses > 1){
            const url = getURL(environment, 'teamPlan');
            window.open(url, '_blank').focus();
            return;
        }
        setStateExpanded(!stateExpanded);
    }

    const handleOnChangePeriodicity = ({ target }) => {
        const action = {
            type: 'changePeriodicity',
            payload: target.value
        };
        mainReducer.dispatch(action);
    }

    const handleOnChangeId = ({ target }) => {
        const action = {
            type: 'changeIdLicense',
            payload: target.value
        };
        mainReducer.dispatch(action);
    }

    return <>
        <section className={`e__plns__hdg${(stateExpanded) ? ' act' : ''}`}>
            <div className="e__plns__hdg__wpr">
                <div className="e__plns__hdg__wrp">
                    <div className='e__plns__hdg__wrp__lst'>
                        {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense <= mainReducer.state.currentLicense.id)) &&
                            <div className='e__plns__hdg__wrp__lst__plan'>
                                <div className="e__plns__lst__hd__hdg">{getText('plan_asignado')}:</div>
                                <BlockInfoLicense license={{ id: mainReducer.state.subLicense }} />
                                <span className="e__plns__lst__hd__ico">
                                    <SVG_back />
                                </span>
                            </div>
                        }
                        {((mainReducer.state.inProgressLicense.id == mainReducer.state.currentLicense.id) && (mainReducer.state.inProgressLicense.periodicity == mainReducer.state.currentLicense.periodicity) && (mainReducer.state.inProgressLicense.licenses == mainReducer.state.currentLicense.licenses)) ?
                            <div className='e__plns__hdg__wrp__lst__plan'>
                                <div className="e__plns__lst__hd__hdg">{getText('proxima_renovacion')} {tsToDate(mainReducer.state.inProgressLicense.expiration, environment.lang)}:</div>
                                <BlockInfoLicense license={mainReducer.state.currentLicense} />
                                <span className="e__plns__lst__hd__ico">
                                    <SVG_back />
                                </span>
                            </div>
                            :
                            <>
                                <div className='e__plns__hdg__wrp__lst__plan'>
                                    <div className="e__plns__lst__hd__hdg">{getText('plan_contratado')}:</div>
                                    <BlockInfoLicense license={mainReducer.state.inProgressLicense} />
                                    <span className="e__plns__lst__hd__ico">
                                        <SVG_back />
                                    </span>
                                </div>
                                <div className='e__plns__hdg__wrp__lst__plan'>
                                    <div className="e__plns__lst__hd__hdg">{getText('a_partir_de')} {tsToDate(mainReducer.state.inProgressLicense.expiration, environment.lang)}:</div>
                                    <BlockInfoLicense license={mainReducer.state.currentLicense} />
                                    <span className="e__plns__lst__hd__ico">
                                        <SVG_back />
                                    </span>
                                </div>
                            </>
                        }
                        {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense > mainReducer.state.currentLicense.id)) &&
                            <div className='e__plns__hdg__wrp__lst__plan'>
                                <div className="e__plns__lst__hd__hdg">{getText('plan_asignado')}:</div>
                                <BlockInfoLicense license={{ id: mainReducer.state.subLicense }} />
                                <span className="e__plns__lst__hd__ico">
                                    <SVG_back />
                                </span>
                            </div>
                        }
                    </div>
                </div>
                {(!stateExpanded) &&
                    <button className="e__lnk" title={getText('cambiar')} onClick={handleEdit}>
                        {getText('cambiar')}
                    </button>
                }
            </div>
            {(mode == 'edit') &&
                <button className="e__lnk" title={getText('cancelar_suscripcion')} onClick={handleCancel}>
                    {getText('cancelar_suscripcion')}
                </button>
            }
        </section>
        {(stateExpanded) &&
            <fieldset className="e__plns__opt__box act">
                <legend>
                    {getText('opciones_de_plan')}
                    <span className="e__btn__info">
                        <button className="e__btn" aria label={getText('mas_informacion')} title={getText('mas_informacion')}>i</button>
                        <span className="e__btn__info__wp">{getText('opciones_de_plan_txt')}</span>
                    </span>
                </legend>
                <div className="e__plns__opt__box__wpr">
                    <div className="e__plns__opt__plan">
                        <div className="e__slct e__slct--hd">
                            <div className="e__slct__hd">
                                <label htmlFor="plan_id">{getText('plan_educaplay')}:</label>
                            </div>
                            <select id={`plan_periodicity`} onChange={handleOnChangeId} value={mainReducer.state.selectedLicense.id}>
                                {Object.keys(config.plans).map(plan =>
                                    <Fragment key={plan}>
                                        {(plan > 0) &&
                                            <option value={plan}>
                                                {getText('plan_name_' + plan)}
                                            </option>
                                        }
                                    </Fragment>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="e__plns__opt__lcns">
                        <div className="e__plns__opt__hd">
                            <div>{getText('field_licenses_title')}:</div>
                        </div>
                        <div className="e__count">
                            <div>
                                {mainReducer.state.selectedLicense.licenses}
                            </div>
                        </div>
                    </div>
                    <div className="e__plns__opt__perid">
                        <div className="e__slct e__slct--hd">
                            <div className="e__slct__hd">
                                <label htmlFor="plan_periodicity">{getText('periodicidad')}:</label>
                            </div>
                            <select id={`plan_periodicity`} onChange={handleOnChangePeriodicity} value={mainReducer.state.selectedLicense.periodicity}>
                                {Object.keys(config.periodicities).map(periodicity =>
                                    <option key={periodicity} value={periodicity}>
                                        {getText('periodicity_' + periodicity)}
                                    </option>
                                )}
                            </select>
                            {(mainReducer.state.selectedLicense.periodicity == config.defaultPeriodicity) &&
                                <span className="e__plns__dscount">
                                    <span className="e__plns__dscount__nmbr">{mainReducer.state.discountForPeriodicity[mainReducer.state.selectedLicense.id][mainReducer.state.selectedLicense.periodicity] + '%'}</span>
                                    {getText('porcentaje_ahorro_por_periodicidad')}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </fieldset>
        }
    </>;
}